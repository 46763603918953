
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import {
  getDevShowReportList,
  editDevShowReport,
  addDevShowReport,
    isEditAuthByCode,
  getModelList,
  getProductEliminateDevShowReportList
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  modelOption:any
  customerData: Array<any>
  editFlag: boolean
  editForm: any
  searchDataAny: any //搜索条件
}

export default defineComponent({
  name: 'DevShowReportTable',
  components: {},
  setup() {
    const editRef = ref()
    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件

      customerData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      modelOption: [],
      rules: {
        tbDevModelId: [{ required: true, message: '请请选择产品规格', trigger: 'blur' }],
      },
    })

    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
              getList()
          },50);
    })

    const addFun = () => {
      deviceData.editForm = { isEdit: false }
      deviceData.modelOption = [];
    //   getProductEliminateDevShowReportList().then(
    //     (res: any) => {
    //       if (res.data != null) {
    //         deviceData.modelOption = res.data.list
    //       }
    //     }
    //   )
    getModelList({ isPage: false }).then(
        (res: any) => {
            if (res.data != null) {
                //deviceData.modelOption = res.data.list
                for (let o of res.data.list) {
                    console.info("xxxxxxxxxxxxxxxx");
                    deviceData.modelOption.push({
                    value: o.id,
                    label: o.name
                });
                }
                
            }
        }
    )
      deviceData.editFlag = true
    }

    const editSave = async () => {
      editRef.value.validate(async (valid: any) => {
        if (valid) {
          let fun = addDevShowReport
          if (deviceData.editForm.isEdit) {
            fun = editDevShowReport
            delete deviceData.editForm.createTime
          }

          let array: any = {}
            Object.assign(array, deviceData.editForm)

            // console.log('xxxxxxxxxxx');
            // if (array!=null) {
                
            // console.log('xxxxxxxxxxx');
            //     return;
            // }
        //   let keys = Object.keys(array);
        //   for (let key of keys) {
        //       if(array[key] == null || array[key] == ""){
        //           delete array[key];
        //       }
        //   }

            console.log("xxx");
          
          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          return false
        }
      })
    }

    const selectCustomer = () => {
      deviceData.currentPage = 1
      getList()
    }

    //编辑
    const editorFun = (e: any) => {
      //console.log(e)
      let obj: any = {}
      Object.assign(obj, e)
      deviceData.editForm = obj
      deviceData.editForm.isEdit = true
      deviceData.modelOption.push({
          value: obj.tbDevModelId,
          label: obj.name,
        });
      deviceData.editFlag = true
    }

    const getList = async () => {
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getDevShowReportList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.customerData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.customerData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
            setTimeout(function(){
                deviceData.loading = false
            },100);
            // ElMessage.success('加载成功!')
          }
          else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
                deviceData.loading = false
            }else{
                ElMessage.error("加载失败");
                deviceData.loading = false
            }
          
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }

    return {
      ...data,
      addFun,
      editRef,
      editSave,
      selectCustomer,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
      isEditAuthByCode
    }
  },
})
